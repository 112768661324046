import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

function Skills() {
    const [t] = useTranslation();
    const skillsRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {threshold: 0.3}
        );

        if (skillsRef.current) {
            observer.observe(skillsRef.current);
        }

        return () => {
            if (skillsRef.current) {
                observer.unobserve(skillsRef.current);
            }
        };
    }, []);

    return (
        <div
            ref={skillsRef}
            className={`row d-flex justify-content-center pt-5 pb-5 ${isVisible ? "fade-in-up visible" : "invisible"}`}
            id="skills_id"
        >
            <div className="col-8">
                <h2 className="custom-font p-3 pb-4">{t("top_bar_skills")}</h2>
                <div className="mb-4">
                    <h5 className="custom-font text-start">Java | SpringBoot</h5>
                    <p className="custom-font text-start">Java 8-21 | Springboot 2-3 | Spring Cloud | Spring Retry |
                        Spring Security</p>
                    <div className="progress" style={{height: '20px'}}>
                        <div
                            className={`progress-bar bg-success custom-progress-bar`}
                            role="progressbar"
                            style={{
                                width: isVisible ? '95%' : '0%',
                                transition: "width 1.5s ease-in-out",
                            }}
                            aria-valuenow="95"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            95%
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <h5 className="custom-font text-start">JavaScript | NodeJs</h5>
                    <p className="custom-font text-start">Vanilla | React | Express</p>
                    <div className="progress" style={{height: '20px'}}>
                        <div
                            className={`progress-bar bg-warning custom-progress-bar`}
                            role="progressbar"
                            style={{
                                width: isVisible ? '40%' : '0%',
                                transition: "width 1.5s ease-in-out",
                            }}
                            aria-valuenow="40"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            40%
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <h5 className="custom-font text-start">Unit | Integration Testing</h5>
                    <p className="custom-font text-start">Junit4 | Junit5 | Mockito | AssertJ | Jimfs | JMH</p>
                    <div className="progress" style={{height: '20px'}}>
                        <div
                            className={`progress-bar bg-danger custom-progress-bar`}
                            role="progressbar"
                            style={{
                                width: isVisible ? '100%' : '0%',
                                transition: "width 1.5s ease-in-out",
                            }}
                            aria-valuenow="100"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            100%
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <h5 className="custom-font text-start">PHP | Laravel</h5>
                    <p className="custom-font text-start">Blade | XAMPP</p>
                    <div className="progress" style={{height: '20px'}}>
                        <div
                            className={`progress-bar bg-info custom-progress-bar`}
                            role="progressbar"
                            style={{
                                width: isVisible ? '50%' : '0%',
                                transition: "width 1.5s ease-in-out",
                            }}
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            50%
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <h5 className="custom-font text-start">Kotlin | Android</h5>
                    <p className="custom-font text-start">Jetpack compose | Material theme 3 | Hilt dagger</p>
                    <div className="progress" style={{height: '20px'}}>
                        <div
                            className={`progress-bar bg-primary custom-progress-bar`}
                            role="progressbar"
                            style={{
                                width: isVisible ? '60%' : '0%',
                                transition: "width 1.5s ease-in-out",
                            }}
                            aria-valuenow="60"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            60%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Skills;

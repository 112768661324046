import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import valutaOnline from "../asserts/valuta-online.png";
import vremeaZilei from "../asserts/vremea-zilei.png";
import breakHub from "../asserts/breakhub.png";

function Projects() {
    const [t] = useTranslation();
    const skillsRef = useRef(null);
    const scrollContainerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [centerIndex, setCenterIndex] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [visibleProjects, setVisibleProjects] = useState(0);
    const [centeredCardOffsets, setCenteredCardOffsets] = useState([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {threshold: 0.3}
        );

        if (skillsRef.current) {
            observer.observe(skillsRef.current);
        }

        return () => {
            if (skillsRef.current) {
                observer.unobserve(skillsRef.current);
            }
        };
    }, []);

    const projects = [
        {
            title: "VALUTA ONLINE",
            image: valutaOnline,
            description: t("valuta_online_description"),
            url: "https://www.valutaonline.ro"
        },
        {
            title: "VREMEA ZILEI",
            image: vremeaZilei,
            description: t("vremea_zilei_description"),
            url: "https://www.vremeazilei.ro/"
        },
        {
            title: "BREAKHUB",
            image: breakHub,
            description: t("break_hub_description"),
            url: "https://www.breakhub.click/"
        }
    ];

    const cardWidth = 288;
    const cardGap = 16;
    const cardHeight = 400;

    useEffect(() => {
        const updateDimensions = () => {
            if (scrollContainerRef.current) {
                const width = scrollContainerRef.current.clientWidth;
                setContainerWidth(width);
                const visibleCount = Math.floor((width + cardGap) / (cardWidth + cardGap));
                setVisibleProjects(visibleCount);
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        if (containerWidth > 0 && scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const offsets = [];

            for (let i = 0; i < projects.length; i++) {
                const cardLeft = i * (cardWidth + cardGap);
                const cardCenter = cardLeft + (cardWidth / 2);
                const containerCenter = container.clientWidth / 2;
                offsets.push(cardCenter - containerCenter);
            }

            setCenteredCardOffsets(offsets);
        }
    }, [containerWidth, projects.length]);

    const handleScrollLeft = () => {
        if (centerIndex > 0) {
            const newIndex = centerIndex - 1;
            setCenterIndex(newIndex);
            scrollToCard(newIndex);
        }
    };

    const handleScrollRight = () => {
        if (centerIndex < projects.length - 1) {
            const newIndex = centerIndex + 1;
            setCenterIndex(newIndex);
            scrollToCard(newIndex);
        }
    };

    const scrollToCard = (index) => {
        if (scrollContainerRef.current && centeredCardOffsets.length > index) {
            const container = scrollContainerRef.current;
            const offset = centeredCardOffsets[index];

            container.scrollTo({
                left: container.scrollLeft + offset,
                behavior: 'smooth'
            });
        }
    };

    const handleScroll = () => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const scrollLeft = container.scrollLeft;
            const containerCenter = container.clientWidth / 2;

            let minDistance = Infinity;
            let closestIndex = centerIndex;

            projects.forEach((_, index) => {
                const cardLeft = index * (cardWidth + cardGap);
                const cardCenter = cardLeft + (cardWidth / 2);
                const cardPosition = cardCenter - scrollLeft;
                const distance = Math.abs(cardPosition - containerCenter);

                if (distance < minDistance) {
                    minDistance = distance;
                    closestIndex = index;
                }
            });

            if (closestIndex !== centerIndex) {
                setCenterIndex(closestIndex);
            }
        }
    };

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [centerIndex]);

    const needsScrolling = projects.length > visibleProjects;

    return (
        <div
            ref={skillsRef}
            className={`row d-flex justify-content-center pt-5 pb-5 ${isVisible ? "fade-in-up" : "invisible"}`}
        >
            <div className="col-8 pt-5 pb-4 ps-4" id="projects_id">
                <h2 className="custom-font">{t("top_bar_projects")}</h2>
            </div>
            <div className="col-8">
                <div className="row d-flex justify-content-center">
                    <div className="d-flex justify-content-center align-items-center position-relative">
                        {needsScrolling && (
                            <button
                                className="btn btn-light me-2 position-absolute start-0 z-3"
                                onClick={handleScrollLeft}
                                disabled={centerIndex === 0}
                                style={{transform: "translateX(-50px)"}}
                            >
                                <i className="bi bi-chevron-left"></i>
                            </button>
                        )}

                        <div
                            ref={scrollContainerRef}
                            className="d-flex"
                            style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                overflowX: needsScrolling ? "auto" : "visible",
                                whiteSpace: "nowrap",
                                gap: "1rem",
                                paddingBottom: "20px",
                                paddingTop: "10px",
                                width: "100%",
                                scrollbarWidth: "none",
                                msOverflowStyle: "none",
                                position: "relative",
                                justifyContent: needsScrolling ? "flex-start" : "center"
                            }}
                            onScroll={handleScroll}
                        >
                            {projects.map((project, index) => {
                                const isCentered = needsScrolling ?
                                    index === centerIndex :
                                    Math.abs(index - Math.floor(projects.length / 2)) < 0.6;

                                return (
                                    <div
                                        key={index}
                                        className="card d-flex flex-column"
                                        style={{
                                            width: "18rem",
                                            minWidth: "18rem",
                                            height: cardHeight,
                                            flex: "0 0 auto",
                                            transform: isCentered ? "translateY(-20px)" : "none",
                                            transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                            boxShadow: isCentered ? "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)" : "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                            scrollSnapAlign: "center",
                                            zIndex: isCentered ? 2 : 1
                                        }}
                                    >
                                        <div style={{height: "40%", overflow: "hidden"}}>
                                            <img
                                                className="card-img-top"
                                                src={project.image}
                                                alt={project.title}
                                                style={{objectFit: "cover", height: "100%", width: "100%"}}
                                            />
                                        </div>
                                        <div className="card-body d-flex flex-column flex-grow-1">
                                            <h5 className="card-title text-secondary">{project.title}</h5>
                                            <p className="card-text text-secondary text-wrap flex-grow-1">{project.description}</p>
                                            <a href={project.url} className="btn btn-light w-100 mt-auto">
                                                {t("projects_details")}
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {needsScrolling && (
                            <button
                                className="btn btn-light ms-2 position-absolute end-0 z-3"
                                onClick={handleScrollRight}
                                disabled={centerIndex === projects.length - 1}
                                style={{transform: "translateX(50px)"}}
                            >
                                <i className="bi bi-chevron-right"></i>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;
import profileImage from "../asserts/profile.avif";
import {useEffect, useState} from "react";
import MainSkills from "../components/MainSkills";
import Intro from "../components/Intro";
import TopBar from "../components/TopBar";
import BottomBar from "../components/BottomBar";
import Skills from "../components/Skills";
import Contact from "../components/Contact";
import StickyArrow from "../components/StickyArrow";
import {useTranslation} from "react-i18next";
import {CookieConsent} from "react-cookie-consent";
import Projects from "../components/Projects";

function Home() {

    const [t] = useTranslation();
    const [text, setText] = useState("");
    const [index, setIndex] = useState(0);
    const [word, setWord] = useState(0);
    const [shouldDelete, setShouldDelete] = useState(false);

    const typeSpeed = 80;
    const deleteSpeed = 30;
    const waitBeforeDelete = 600;

    useEffect(() => {
        const phrases = [" ", t("home_phrase_one"), t("home_phrase_two"), t("home_phrase_three"), t("home_phrase_four")];
        if (word === phrases.length) {
            return;
        }

        const timer = setTimeout(() => {
            if (shouldDelete) {
                if (text.length === phrases[word].length) {
                    setTimeout(() => {
                        setText((prevText) => prevText.substring(0, prevText.length - 1));
                    }, waitBeforeDelete);
                } else if (text.length === 0) {
                    setShouldDelete(false);
                    setWord((prevWord) => prevWord + 1);
                    setIndex(0);
                } else {
                    setText((prevText) => prevText.substring(0, prevText.length - 1));
                }
            } else {
                setText((prevText) => prevText + phrases[word][index]);
                if (index === phrases[word].length - 1) {
                    if (word === phrases.length - 1) {
                        setWord(phrases.length);
                    } else {
                        setShouldDelete(true);
                    }
                } else {
                    setIndex((prevIndex) => prevIndex + 1);
                }
            }
        }, shouldDelete ? deleteSpeed : typeSpeed);

        return () => clearTimeout(timer);
    }, [text, shouldDelete, word, index, deleteSpeed, typeSpeed]);


    return (
        <div className="App">
            <StickyArrow/>
            <TopBar/>
            <div className="container-fluid">
                <div className="row d-flex justify-content-center mt-5 mb-5">
                    <div className="col-12 col-sm-4 d-flex justify-content-center align-items-center">
                        <h2 className="custom-font text-center" id="text_id">
                            {text}
                        </h2>
                    </div>
                    <div className="col-12 col-sm-4 d-flex justify-content-center align-items-center">
                        <img
                            src={profileImage}
                            alt="Profile"
                            className="rounded-circle round-image mt-4 mb-4"
                        />
                    </div>
                </div>
                <Intro/>
                <MainSkills/>
                <Projects/>
                <Skills/>
                <Contact/>
                <BottomBar/>

                <CookieConsent
                    flipButtons
                    location="bottom"
                    buttonText={t("home_cookie_accept_button")}
                    declineButtonText={t("home_cookie_decline_button")}
                    cookieName="isCookieAllowed"
                    expires={7}
                    enableDeclineButton
                    overlay
                    buttonStyle={{
                        background: '#1a73e8',
                        color: 'white',
                        borderRadius: '5px',
                        padding: '6px 50px',
                    }}
                    declineButtonStyle={{
                        background: '#b0b0b0',
                        color: 'white',
                        borderRadius: '5px',
                        padding: '6px 50px',
                    }}
                >
                    {t("home_cookie_message")}
                </CookieConsent>
            </div>
        </div>
    );
}

export default Home;

import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import AxiosService from "../services/AxiosService";
import {FailAlert, SuccessAlert} from "./Alerts";

function Contact() {

    const [isDisabledFailAlert, setIsDisabledFailAlert] = useState(true);
    const [isDisabledSuccessAlert, setIsDisabledSuccessAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState("")
    const [t] = useTranslation();
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: ""
    });
    const skillsRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {threshold: 0.5}
        );

        if (skillsRef.current) {
            observer.observe(skillsRef.current);
        }

        return () => {
            if (skillsRef.current) {
                observer.unobserve(skillsRef.current);
            }
        };
    }, []);

    const handleChange = (e) => {
        const {id, value} = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [id]: value
        }));
    };

    function resetFields() {
        setForm({
            name: "",
            email: "",
            message: ""
        });
    }

    async function sendEmail() {
        const axiosService = new AxiosService();
        const response = await axiosService.post('/', {
            name: form.name,
            email: form.email,
            message: form.message
        });

        resetFields();

        if (response === null) {
            setAlertMessage(t("contact_fail_null_message"))
            setIsDisabledFailAlert(false)
        }

        if (response.status !== 204) {
            setAlertMessage(t("contact_fail_invalid_status_message"))
            setIsDisabledFailAlert(false)
        }

        setAlertMessage(t("contact_success_message"))
        setIsDisabledSuccessAlert(false)
    }

    const isFormValid = form.name.trim() && form.email.trim() && form.message.trim();

    return (
        <div className="row d-flex justify-content-center pt-5 pb-5" id="contact_id"
             style={{backgroundColor: "#31473A", borderRadius: "30px 30px 0px 0px"}}>
            <FailAlert message={alertMessage} isDisabled={isDisabledFailAlert} setIsDisabled={setIsDisabledFailAlert}/>
            <SuccessAlert message={alertMessage} isDisabled={isDisabledSuccessAlert}
                          setIsDisabled={setIsDisabledSuccessAlert}/>

            <div ref={skillsRef} className={`col-8 ${isVisible ? "fade-in-up" : "invisible"}`}>
                <h2 className="text-white p-3 pb-4">{t("contact_title")}</h2>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label text-white">{t("contact_name")}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={form.name}
                            onChange={handleChange}
                            style={{backgroundColor: "#e1eae5"}}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="email" className="form-label text-white">{t("contact_email")}</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={form.email}
                            onChange={handleChange}
                            style={{backgroundColor: "#e1eae5"}}
                        />
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="message" className="form-label text-white">{t("contact_message")}</label>
                    <textarea
                        className="form-control"
                        id="message"
                        rows="8"
                        value={form.message}
                        onChange={handleChange}
                        style={{backgroundColor: "#e1eae5"}}
                    ></textarea>
                </div>
                <div className="custom-font text-center">
                    <button
                        type="button"
                        className="btn btn-light"
                        style={{width: "150px"}}
                        disabled={!isFormValid}
                        onClick={() => sendEmail()}
                    >
                        {t("contact_button")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Contact;

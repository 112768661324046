import leetCode from "../asserts/leetcode.webp";
import gitHub from "../asserts/github.png";
import cv from "../asserts/cv.png";
import cvPDF from "../asserts/CV_Robert_Baltaru_March.pdf";

import {useTranslation} from "react-i18next";

function BottomBar() {

    const [t] = useTranslation();

    function downloadCV() {
        const link = document.createElement('a');
        link.href = cvPDF;
        link.download = 'resume.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="row d-flex justify-content-center"
             style={{backgroundColor: "#31473A"}}>
            <div className="col-10 d-flex flex-column align-items-center text-center mt-5">
                <div>
                    <p className="text-white">
                        {t("bottom_bar_rights_reserved")}
                    </p>
                </div>
                <div>
                    <p className="text-white">
                        {t("bottom_bar_github_leet_code")}
                    </p>
                </div>
                <div className="mb-5 text-white">
                    <p>
                        <a href="https://github.com/D3adSmil33" className="m-1">
                            <img src={gitHub} alt="GitHub" style={{width: '35px', height: '35px'}}/>
                        </a>
                        |
                        <a href="https://leetcode.com/u/35Zoll" className="m-1">
                            <img src={leetCode} alt="LeetCode" style={{width: '35px', height: '35px'}}/>
                        </a>
                        |
                        <a className="m-1">
                            <img onClick={() => downloadCV()} src={cv} alt="LeetCode"
                                 style={{width: '35px', height: '35px', cursor: "pointer"}}/>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default BottomBar;
